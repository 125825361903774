//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import { mapState } from 'vuex';
  import dummyItem from '~/components/content/dummy/video_dummy.vue';

  export default {
    async fetch() {
      let query = {
        type: 'recommended',
        loadType: 'recommendedVideos',
        include: 'images:types(thumb|thumb_mobile),products,categories',
        page: 1,
        count: 10,
        api_type: 'video',
        sort: {
          recommended_at: 'DESC',
        },
        filter: {
          status: 'published',
          products: '2',
          recurring: 1,
        },
      };
      if (
        process.server ||
        (!process.server &&
          this.isEmpty(this.$store.getters['videoStore/recommendedVideos']))
        ) {
        await this.$store.dispatch('videoStore/getVideos', query);
    }
  },
  components: {
    sectionTitle: () => import('~/components/section_title'),
    slider: () => import('~/components/slider'),
    dummyItem,
  },
  computed: {
    ...mapState({
      videos: ({ videoStore: { recommendedVideos } }) => recommendedVideos,
      loadState: ({ loadstateStore: { loadState } }) => loadState,
    }),
  },
  mounted: function () {
    if (this.videos?.length) {
      var loadingObj = {
        content: 'recommendedVideos',
        type: 'done',
      };
      this.$store.commit('loadstateStore/setLoadState', loadingObj);
    }
  },
};
